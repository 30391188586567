import { Center, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type LoadingContextProps = {
  loading: (state: boolean) => void;
};

// TODO: Please change text to JP
const defaultData = {
  loading: () => {
    throw new Error('State not passed');
  },
};

const LoadingContext = createContext<LoadingContextProps>(defaultData);

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode>(undefined);
  const showLoading = useCallback(() => {
    const close = () => {
      setModal(undefined);
    };
    setModal(
      <Modal
        isOpen={true}
        onClose={close}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        variant='ghost'
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {/* TODO: Change style */}
            <Center>
              <Spinner
                size='xl'
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='primary.500'
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }, []);

  const hideLoading = useCallback(() => {
    setModal(undefined);
  }, []);

  const loading = (state: boolean) => {
    if (state) showLoading();
    else hideLoading();
  };
  return (
    <LoadingContext.Provider value={{ loading }}>
      {children}
      {modal}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
